html {
  display: block;
}

body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

body {
  position: relative;
  overflow: hidden;
  background-color: #000;

  @include sm-and-below {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 14px;
  line-height: 1.7;
}

a{
  color: white;
  text-decoration: none;
  &:hover, &:focus {
    color: white;
    text-decoration: none;
  }
}

.home, .work {
  position: absolute;
  left: 0;
  top: 0;
}

.logo, .logo img, .home,.home .mask-image, .work, .work .mask-image {
  width: 100%;
  height: 100%;
}

.line-hover{
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  color: #666;
  display: inline-block;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  transition: color .8s cubic-bezier(0.19,1,.22,1);
  vertical-align: top;
  webkit-transition: color .8s cubic-bezier(0.19,1,.22,1);
  white-space: nowrap;
}

.line-hover-active{
  color: #FFF;
  display: inline-block;
  overflow: hidden;
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  transition: color .8s cubic-bezier(0.19,1,.22,1);
  vertical-align: top;
  webkit-transition: color .8s cubic-bezier(0.19,1,.22,1);
  white-space: nowrap;
  pointer-events: none;
  cursor: default;
}

.line-hover:after{
  background-color: #fff;
  content: '';
  display: block;
  height: 2px;
  left: 0;
  //margin-top: -1px;
  ms-transform: translateX(-100%);
  position: absolute;
  top: 50%;
  transform: translateX(-100%);
  transition: transform 1s cubic-bezier(0.19,1,.22,1);
  transition-delay: .1s;
  webkit-transform: translateX(-100%);
  webkit-transition: -webkit-transform 1s cubic-bezier(0.19,1,.22,1);
  webkit-transition-delay: .1s;
  width: 100%;
}
.line-hover:hover{
  color: #FFF;
}
.line-hover:hover:after{
  ms-transform: translateX(100%);
  transform: translateX(100%);
  webkit-transform: translateX(100%);
}

.header-left{
  margin-left: 55px;
  margin-right: 55px;
  display: inline-block;
  white-space: nowrap;

  @include sm-and-below {
    font-size: 20px;
    margin-left: 0;
    margin-right: 0;
    width: 100%;

    .line-hover {
      width: 50%;
      text-align: center;
    }

    .line-hover-active {
      width: 50%;
      text-align: center;
    }
  }
}

.header-right {
  color: #666;
  display: inline-block;
  white-space: nowrap;
  .line-hover {
    cursor: default;
  }
}

.visa-master {
  width: 100px;
}

.about-us-image {
  img {
    width: 96%;
    height: auto;
    background-color: #fff;
    padding: 3%;
  }
  video {
    width: 96%;
    background-color: #fff;
    padding: 3%;
  }
}

.about-us-image-mobile {
  margin-top: 20px;
  img {
    width: 96%;
    height: auto;
    background-color: #fff;
    padding: 3%;
  }
}

.align-by-vertical {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.donate-btn {
  background: white;
  color: black;
  padding: 6px 30px;
  border-radius: 20px;
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border: none;
  &:hover, &:focus {
    color: gray;
    a {
      color: gray;
    }
  }
  a {
    color: black;
  }
  &.left {
    border-radius: 20px 0 0 20px;
    margin-right: 1px;
  }
  &.right {
    border-radius: 0 20px 20px 0;
    margin-left: 1px;
  }

  @include sm-and-below {
    padding: 6px 30px;
    font-size: 14px;
  }
}

.about-us-text {
  color: #FFF;
  font-family: 'Open Sans', sans-serif;
  font-weight: 200;
  font-size: 14px;
  padding-left: 10%;
  padding-right: 10%;
  line-height: 1.7;
  text-align: center;

  .logo {
    max-width: 70%;
    margin-left: auto;
    margin-right: auto;

    @include sm-and-below {
      margin-top: 20px;
    }
  }

  .donate-index-container {
    margin-top: 10px;
  }

  .description {
    margin-top: 20px;

    @include sm-and-below {
      margin-top: 40px;
    }
  }

  .schedule {
    margin-top: 20px;
  }

  .address {
    margin-top: 20px;

    .line-hover {
      @include sm-and-below {
        font-size: 22px;
      }
    }
  }
  .price {
    margin-top: 20px;
    table {
      margin: auto;
      text-align: left;
      td {
        padding: 0 12px;
      }
    }
  }
  .contacts {
    margin-top: 20px;
  }
  .socials {
    margin-top: 20px;
    img {
      padding-left: 3px;
    }

    @include sm-and-below {
      font-size: 22px;
    }
  }
  .donate-items {
    margin-top: 20px;
  }
  .donate-items-long-text {
    max-height: 40vh;
    overflow: hidden;
    overflow-y: auto;
    @include sm-and-below {
        overflow: unset;
        max-height: none;
    }
  }
}
.col-sm-6 {
  min-height: 90%;  /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh; /* These two lines are counted as one :-)       */

  @include sm-and-below {
    width: 100%;
  }
}
.top-bar {
  position: relative;
  margin-bottom: 0;
  height: 60px;
  width: 100vw;
  display: table-cell;
  vertical-align: bottom;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;

  .lang-menu {
    position: absolute;
    right: 0;
    top: 60%;
    @include sm-and-below {
      top: 40%;
    }
  }
}
.navbar-fixed-bottom {
  left: inherit;
  text-align: right;
  padding-right: 55px;
  padding-top: 10px;

  @include sm-and-below {
    left: 0;
    text-align: center;
    position: relative;
    padding-right: 0;
    padding-top: 30px;
  }
}

.donate-notes {
  font-size: 9px;
  margin-top: 10px;
}
.donate-requisites {
  margin-top: 10px;
  text-align:left;
}

.wish-list-slide {
  margin: 20px;

  .title {
    font-weight: bold;
    text-transform: uppercase;
    margin: 10px 0;
  }

  .description {
    overflow-y: auto;
    max-height: 32vh;
    height: 32vh;

    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px grey;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: white;
      border-radius: 10px;
    }
  }

  .progress {
    margin-top: 10px;
    background-color: white;
    box-shadow: none;
    border: white 1px solid;
    border-radius: 12px;
    margin-bottom: 0;
    .progress-bar {
      background: black;
    }
  }

  .statusUAH {
    .currentSum {
      float: left;
    }
    .targetSum {
      float: right;
    }
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 30vh;
    max-height: 30vh;
  }
}

